import React from "react";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Button, Avatar, Dropdown, message } from "antd";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../../../context/auth-context";
const { Header } = Layout;
const Topbar = ({ colorBgContainer, setCollapsed, collapsed }) => {
  // const authValue = useAuth();
  const navigate = useNavigate()
  const handleLogout = ()=>{
    localStorage.clear()
    navigate("/login")
    message.success("Successfully Logged out")
  }
  const menu = [
    {
      label: (
        <Button
          type="text"
          icon={<LogoutOutlined />}
          onClick={()=>handleLogout()}
        >
          Logout
        </Button>
      ),
      key: "1",
    },
  ];
  return (
    <React.Fragment>
      <Header
        className="d-flex justify-content-between align-items-center px-3"
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <Dropdown
          menu={{
            items: menu,
          }}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <div style={{ padding: "4px 15px" }} role="button">
            <Avatar
              size={35}
              style={{ backgroundColor: "#87d068" }}
              icon={<UserOutlined size={60} />}
            />
          </div>
        </Dropdown>
      </Header>
    </React.Fragment>
  );
};

export default Topbar;
