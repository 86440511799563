import React, { useEffect, useState } from "react";
import { Layout, Menu, Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { menu } from "../DashbordMenu";
import MyDesktop from "../../../UtilComponents/MyDesktop";
import MyMobile from "../../../UtilComponents/MyMobile";
// import { useAuth } from "../../../../../context/auth-context";
import Logo from "../../../../assets/Logo/Schools-Universe-Logo.png";
import smallLogo from "../../../../assets/Logo/favicon.png";


const { Sider } = Layout;
const userDetails = JSON.parse(localStorage.getItem("userDetails"))?.user_data;
const Sidebar = ({ collapsed, setCollapsed, colorBgContainer }) => {
  const navigate = useNavigate();
  // const authData = useAuth();

  const [defaultSelectedMenu, setDefaultSelectedMenu] = useState("1");
  useEffect(() => {
    let pathname = window.location.pathname;
    let selectedMenu = menu?.filter((each) => each.route === pathname)[0];
    if (selectedMenu) {
      setDefaultSelectedMenu(selectedMenu?.key);
    } else {
      setDefaultSelectedMenu("-1");
    }
  }, [window.location.pathname]);

  let menus = [];
  for (let i = 0; i < menu?.length; i++) {
    let obj = menu[i];
    if (menu[i]?.children) {
      let children = menu[i]?.children;
      for (let j = 0; j < children?.length; j++) {
        children[j].onClick = () => {
          navigate(children[j].route);
        };
      }
    } else if (menu[i]?.route) {
      menu[i].onClick = () => {
        navigate(menu[i].route);
      };
    }
    if (obj?.role?.includes(userDetails?.roles?.id)) menus.push(obj);
  }
  return (
    <React.Fragment>
      <MyDesktop>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          style={{ background: "#C5C7C7" }}
        >
          {/* <Space
            className="demo-logo-vertical p-2 py-3 text-center text-white d-flex justify-content-center"
            align="end"
            style={{ letterSpacing: "1px", fontSize: "16px", gap: 2.2 }}
          >
            <img src={Logo} height={30} />
            {!collapsed && "ellDone"}
          </Space> */}
          <div className="text-white text-center p-3">
            <img
              src={!collapsed ? Logo : smallLogo}
              // src=""
              width={"90%"}
              alt="School Universe"
            />
          </div>
          <Menu
            theme="dark"
            className="menu-label"
            style={{ background: "#c5c7c7",}}
            mode="inline"
            selectedKeys={[defaultSelectedMenu]}
            items={menus}
          />
        </Sider>
      </MyDesktop>
      <MyMobile>
        <Drawer
          style={{ maxWidth: "256px", background: "#2a3649", padding: 0 }}
          placement="left"
          open={!collapsed}
          className="th-mobile-sidebar"
          onClose={() => {
            setCollapsed(!collapsed);
          }}
          closeIcon={null}
          contentWrapperStyle={{ width: "256px" }}
          title={<div className="text-white text-center">LOGO</div>}
        >
          <Menu
            theme="dark"
            mode="inline"
            style={{ background: "#2a3649" }}
            selectedKeys={[defaultSelectedMenu]}
            items={menus}
          />
        </Drawer>
      </MyMobile>
    </React.Fragment>
  );
};

export default Sidebar;
