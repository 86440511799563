import React, { lazy, useEffect, useState } from "react";
import AllRoutes from "./AllRoutes/AllRoutes";
import Navbar from "./App/Components/Navbar/Navbar";
import axios from "axios";
import { BASE_URL } from "./App/utils/apiConfig";
import DashboardLayout from "./App/Components/Layout/DashboardLayout";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;

  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";

  return (
    <>
      <AllRoutes />
    </>
  );
};

export default App;
