import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({children}) => {

    const isLoggedIn = JSON.parse(localStorage.getItem("userDetails"))

    const navigate = useNavigate()

    useEffect(()=>{
        if(!isLoggedIn){
            message.error("Please Login First")
            return navigate("/login")
        }
    },[isLoggedIn])
 
  return children
};

export default PrivateRoute;
